<template>
  <v-dialog
    :value="show"
    persistent
    width="500"
    @click:outside="handleClickOutside"
    @keydown="handleKeydown"
  >
    <v-card>
      <v-form v-model="form.valid" @submit.prevent="save">
        <v-card-title>Einstellungen</v-card-title>

        <v-card-text>
          <v-switch
            label="Ohne Audio starten"
            v-model="settings.startWithAudioMuted"
          ></v-switch>

          <v-switch
            label="Ohne Video starten"
            v-model="settings.startWithVideoMuted"
          ></v-switch>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <temeno-circle-button
            action
            title="abbrechen"
            @click="handleClickCancelButton"
          >
            $close
          </temeno-circle-button>

          <temeno-circle-button
            action
            title="speichern"
            type="submit"
            :disabled="!form.valid"
          >
            {{ !form.valid ? '$approve_action_disabled' : '$approve' }}
          </temeno-circle-button>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "SettingsDialog",
  components: {
    TemenoCircleButton: () => import("@/components/TemenoCircleButton"),
  },
  props: {
    show: {
      type: Boolean,
    },
  },
  data() {
    return {
      form: {
        valid: false,
      },
      settings: {
        startWithAudioMuted: null,
        startWithVideoMuted: null,
      },
    };
  },
  watch: {
    show(value) {
      if (value) {
        const {
          startWithAudioMuted,
          startWithVideoMuted,
        } = this.$store.state.settings;
        this.settings.startWithAudioMuted = startWithAudioMuted;
        this.settings.startWithVideoMuted = startWithVideoMuted;
      }
    },
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    handleClickCancelButton() {
      this.cancel();
    },
    handleClickOutside() {
      this.cancel();
    },
    handleKeydown(key) {
      if (key.code === "Escape") {
        // treat Esc like click outside
        this.handleClickOutside();
      }
    },
    save() {
      this.$store.dispatch("settings/update", this.settings);
      this.$emit("save");
    },
  },
};
</script>
